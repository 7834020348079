.sidebar .rc-menu{
    border-top:0 !important;
    border-left:0 !important;
    box-shadow: 0 0 0px black;
    color: white !important;
    cursor:pointer;
  }
    
  .sidebar .rc-menu-sub{
background-color: #0B131A !important;
}

.sidebar .rc-menu-submenu-title, .sidebar .rc-menu-submenu{
background-color: #0B131A !important;
}

.sidebar .rc-menu-item-active{
background-color: #0B131A !important;
}

.sidebar .rc-menu-item-selected{
  background-color: #0B131A !important;
  }

.sidebar .rc-menu li:hover ,  .sidebar  .rc-menu-submenu-title:hover{
background-color: #13b94d !important;
}

.sidebar{
  background-color:#0B131A
}

